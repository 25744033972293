<template>
  <el-row type="flex" class="row-bg">
    <el-form-item label="关键词" prop="keyword" label-width="70px">
      <el-input v-model="query.keyword" placeholder="可款式搜索" @keyup.enter="fetchs()"
        style="max-width: 100px;"></el-input>
    </el-form-item>

    <el-form-item label="库房" prop="storeCode" label-width="70px">
      <el-select v-model="query.storeCode" placeholder="库房" size="default" @change="fetchs()" clearable
        style="min-width: 90px;">
        <el-option v-for="item in stores" :key="item.value" :label="item.label" :value="item.value"> </el-option>
      </el-select>
    </el-form-item>

    <el-form-item label="公司" prop="companyId" label-width="70px">
      <el-tree-select ref="companys" v-model="query.companyId" :data="companys" @change="companyChange();" clearable
        check-strictly :render-after-expand="false" placeholder="请选择公司" style="min-width:210px" />
    </el-form-item>
    <el-form-item label="部门" prop="departmentId" label-width="70px">
      <el-tree-select ref="departments" v-model="query.departmentId" :data="departments" clearable check-strictly
        @change="fetchs()" :render-after-expand="false" style="min-width:120px" />
    </el-form-item>
    <el-form-item label="时间" label-width="70px">
      <el-date-picker v-model="dateRange" type="daterange" start-placeholder="开始时间" end-placeholder="结束时间" unlink-panels
        @change="fetchs()" :shortcuts="$util.getShortCuts()" style="max-width:210px;"> </el-date-picker>
    </el-form-item>

    <el-button style="margin: 0 10px" size="default" type="primary" @click="fetchs"> 确认 </el-button>
  </el-row>

  <el-tabs type="border-card" v-model="tabName" @tab-change="fetchs()">
    <el-tab-pane label="销售排名" name="sellrank">
      <div class="skuSellRank" id="skuSellRank" style="width:80vw; height: 500px; margin:10px auto;"> </div>

      <el-table class="table" ref="skuSellData" :data="skuSellData" border size="small"
        style="width:60vw;margin:0px auto;">
        <el-table-column type="index" label="序" align="center" width="40"> </el-table-column>
        <el-table-column prop="detailCode" label="款号" show-overflow-tooltip sortable> </el-table-column>
        <el-table-column label="图片" show-overflow-tooltip width="100">
          <template #default="item">
            <el-image style="height: 32px; width: 32px"
              :src="item.row.imgSrc"
              :preview-src-list="[item.row.imgSrc]">
              <template #error>
                <img style="width: 100%" src="~@/assets/imgunfine.jpg" alt="" />
              </template>
            </el-image>
          </template>
        </el-table-column>

        <el-table-column prop="detailName" label="款名" show-overflow-tooltip sortable> </el-table-column>
        <el-table-column prop="serialCode" label="自定义款号" show-overflow-tooltip sortable> </el-table-column>
        <el-table-column prop="sellPrice" label="销售价" width="100" align="center" sortable>
          <template #default="item">{{ $util.fmtMoney(item.row.sellPrice || '-') }}</template>
        </el-table-column>
        <el-table-column prop="stockNumber" label="出库数量" width="100" align="center" sortable>
          <template #default="item">{{ $util.fmtMoney(item.row.stockNumber || '-') }}</template>
        </el-table-column>
      </el-table>
    </el-tab-pane>
    <el-tab-pane label="销售占比" name="sellratio">
      <div class="skuSellRatio" id="skuSellRatio" style="width:80vw; height: 500px; margin:10px auto;"> </div>

      <el-table class="table" ref="skuSellData" :data="skuSellData" border size="small"
        style="width:60vw;margin:0px auto;">
        <el-table-column type="index" label="序" align="center" width="40"> </el-table-column>
        <el-table-column prop="detailCode" label="款号" show-overflow-tooltip sortable> </el-table-column>
        <el-table-column label="图片" show-overflow-tooltip width="100">
          <template #default="item">
            <el-image style="height: 32px; width: 32px"
              :src="item.row.imgSrc"
              :preview-src-list="[item.row.imgSrc]">
              <template #error>
                <img style="width: 100%" src="~@/assets/imgunfine.jpg" alt="" />
              </template>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="detailName" label="款名" show-overflow-tooltip sortable> </el-table-column>
        <el-table-column prop="serialCode" label="自定义款号" show-overflow-tooltip sortable> </el-table-column>
        <el-table-column prop="sellPrice" label="销售价" width="100" align="center" sortable>
          <template #default="item">{{ $util.fmtMoney(item.row.sellPrice || '-') }}</template>
        </el-table-column>
        <el-table-column prop="stockNumber" label="出库数量" width="100" align="center" sortable>
          <template #default="item">{{ $util.fmtMoney(item.row.stockNumber || '-') }}</template>
        </el-table-column>
      </el-table>
    </el-tab-pane>
    <el-tab-pane label="退货占比" name="returnratio">
      <div class="skuReturnRatio" id="skuReturnRatio" style="width:80vw; height: 500px; margin:10px auto;"> </div>

      <el-table class="table" ref="skuReturnData" :data="skuReturnData" border size="small"
        style="width:60vw;margin:0px auto;">
        <el-table-column type="index" label="序" align="center" width="40"> </el-table-column>
        <el-table-column prop="detailCode" label="款号" show-overflow-tooltip sortable> </el-table-column>
        <el-table-column label="图片" show-overflow-tooltip width="100">
          <template #default="item">
            <el-image style="height: 32px; width: 32px"
              :src="item.row.imgSrc"
              :preview-src-list="[item.row.imgSrc]">
              <template #error>
                <img style="width: 100%" src="~@/assets/imgunfine.jpg" alt="" />
              </template>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="detailName" label="款名" show-overflow-tooltip sortable> </el-table-column>
        <el-table-column prop="serialCode" label="自定义款号" show-overflow-tooltip sortable> </el-table-column>
        <el-table-column prop="productPrice" label="成本价" width="100" align="center" sortable>
          <template #default="item">{{ $util.fmtMoney(item.row.productPrice || '-') }}</template>
        </el-table-column>
        <el-table-column prop="stockNumber" label="退货数量" width="120" align="center" sortable>
          <template #default="item">{{ $util.fmtMoney(item.row.stockNumber || '-') }}</template>
        </el-table-column>
      </el-table>
    </el-tab-pane>
    <el-tab-pane label="规格占比" name="unitratio">
      <div class="unitRatio" id="unitRatio" style="width:80vw; height: 500px; margin:10px auto;"> </div>
    </el-tab-pane>
  </el-tabs>
</template>
<script>
import * as echarts from 'echarts';
import StaticDialog from "@/components/static-dialog.vue";
import StaticTable from "@/components/static-table.vue";
export default {
  name: "SellReport",
  components: { StaticDialog, StaticTable },
  data() {
    return {
      tabName: "sellrank",
      query: {
        companyId: '',
        departmentId: '',
        channel: '',
        storeCode: '',
        keyword: '',
        startDate: '',
        endDate: '',
      },
      companys: [],
      departments: [],
      stores: [],
      channels: [],
      dateRange: [],

      skuSellData: [],
      skuReturnData: []
    };
  },
  methods: {
    fetchs() {
      if (this.tabName == 'sellrank') {
        this.$kaung.fetch({ method: 'get', url: '/StockStore/SkuSellReport', params: this.getQuery() })
          .then(res => {
            this.skuSellData = res.data;

            let xCategory = []; let y1Value = []; let y2Value = [];
            res.data.forEach(item => {
              //xCategory.push(item.detailCode + '[' + item.serialCode + ']');
              xCategory.push(item.detailName);
              y1Value.push(item.stockNumber);
              y2Value.push(((item.sellPrice || 0) / 10000).toFixed(2));

              item.imgSrc = process.env.VUE_APP_BASEURL + 'static/code/' + item.detailCode + '?t=0';
            });
            let option = {
              title: {
                text: '畅销TOP30款式的销售数量及金额排名',
                subtext: '根据销售出库数量排名进行统计取前30',
                left: 'center'
              },
              tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'cross',
                  crossStyle: {
                    color: '#999'
                  }
                }
              },
              legend: {
                orient: 'vertical',
                left: 'left',
                data: ['销售数量', '销售金额']
              },
              grid: { y: 90 },
              xAxis: [
                {
                  type: 'category',
                  data: xCategory,
                  axisPointer: {
                    type: 'shadow'
                  },
                  axisLabel: { //设置x轴的字
                    show: true,
                    interval: 2,//使x轴横坐标全部显示
                    formatter: function (value, index) {
                      if (value.length > 10) {
                        return value.substr(0, 10) + '...'
                      } else {
                        return value
                      }
                    }
                  }
                }
              ],
              yAxis: [
                {
                  type: 'value',
                  name: '销售数量/件',
                  axisLabel: {
                    formatter: '{value} 件'
                  }
                },
                {
                  type: 'value',
                  name: '销售金额/万元',
                  axisLabel: {
                    formatter: '{value} 万元'
                  }
                }
              ],
              series: [
                {
                  name: '销售数量',
                  type: 'bar',
                  tooltip: {
                    valueFormatter: function (value) {
                      return value + ' 件';
                    }
                  },
                  data: y1Value
                },
                {
                  name: '销售金额',
                  type: 'bar',
                  yAxisIndex: 1,
                  tooltip: {
                    valueFormatter: function (value) {
                      return value + ' 万元';
                    }
                  },
                  data: y2Value
                }
              ]
            };

            var skuSellRank = document.getElementById('skuSellRank');
            var chartBox = echarts.init(skuSellRank);
            chartBox.setOption(option);
          });
      } 
      else if (this.tabName == 'sellratio') {
        this.$kaung.fetch({ method: 'get', url: '/StockStore/SkuSellReport', params: this.getQuery() })
          .then(res => {
            this.skuSellData = res.data;

            let seriesData = [];
            res.data.forEach(item => {
              seriesData.push({
                //name: item.detailCode + '[' + item.serialCode + ']',
                name: item.detailName,
                value: item.stockNumber
              });
              item.imgSrc = process.env.VUE_APP_BASEURL + 'static/code/' + item.detailCode + '?t=0';
            });

            let option = {
              title: {
                text: '畅销TOP30款式的销售数量占比',
                subtext: '根据销售出库的款式数量前30统计占比',
                left: 'center'
              },
              tooltip: {
                trigger: 'item'
              },
              series: [
                {
                  name: '销售款式数量',
                  type: 'pie',
                  radius: ['40%', '70%'],
                  avoidLabelOverlap: false,
                  itemStyle: {
                    borderRadius: 10,
                    borderColor: '#fff',
                    borderWidth: 2
                  },
                  label: {
                    show: true,
                    position: 'left'
                  },
                  emphasis: {
                    label: {
                      show: true,
                      fontSize: 30,
                      fontWeight: 'bold'
                    }
                  },
                  labelLine: {
                    show: true
                  },
                  data: seriesData
                }
              ]
            };

            var skuSellRatio = document.getElementById('skuSellRatio');
            var chartBox = echarts.init(skuSellRatio);
            chartBox.setOption(option);
          });
      } 
      else if (this.tabName == 'returnratio') {
        this.$kaung.fetch({ method: 'get', url: '/StockStore/SkuSellReport?BillType=客户退货', params: this.getQuery() })
          .then(res => {
            this.skuReturnData = res.data;

            let seriesData = [];
            res.data.forEach(item => {
              seriesData.push({
                //name: item.detailCode + '[' + item.serialCode + ']',
                name: item.detailName,
                value: item.stockNumber
              });
              item.imgSrc = process.env.VUE_APP_BASEURL + 'static/code/' + item.detailCode + '?t=0';
            });

            let option = {
              title: {
                text: 'TOP30款式的退货数量占比',
                subtext: '根据客户退货的款式数量前30统计占比',
                left: 'center'
              },
              tooltip: {
                trigger: 'item'
              },
              series: [
                {
                  name: '退货入库款式数量',
                  type: 'pie',
                  radius: ['40%', '70%'],
                  avoidLabelOverlap: false,
                  itemStyle: {
                    borderRadius: 10,
                    borderColor: '#fff',
                    borderWidth: 2
                  },
                  label: {
                    show: true,
                    position: 'left'
                  },
                  emphasis: {
                    label: {
                      show: true,
                      fontSize: 30,
                      fontWeight: 'bold'
                    }
                  },
                  labelLine: {
                    show: true
                  },
                  data: seriesData
                }
              ]
            };

            var skuReturnRatio = document.getElementById('skuReturnRatio');
            var chartBox = echarts.init(skuReturnRatio);
            chartBox.setOption(option);
          });
      } 
      else if (this.tabName == 'unitratio') {
        this.$kaung.fetch({ method: 'get', url: '/StockStore/UnitSellReport', params: this.getQuery() })
          .then(res => {
            let seriesData = [];
            res.data.forEach(item => {
              seriesData.push({
                name: item.unitName,
                value: item.stockNumber
              });
            });

            let option = {
              title: {
                text: '畅销TOP30产品的规格占比',
                subtext: '根据销售出库货品规格分组的前30统计占比',
                left: 'center'
              },
              tooltip: {
                trigger: 'item'
              },
              series: [
                {
                  name: '销售规格数量',
                  type: 'pie',
                  radius: ['40%', '70%'],
                  avoidLabelOverlap: false,
                  itemStyle: {
                    borderRadius: 10,
                    borderColor: '#fff',
                    borderWidth: 2
                  },
                  label: {
                    show: true,
                    position: 'left'
                  },
                  emphasis: {
                    label: {
                      show: true,
                      fontSize: 30,
                      fontWeight: 'bold'
                    }
                  },
                  labelLine: {
                    show: true
                  },
                  data: seriesData
                }
              ]
            };

            var unitRatio = document.getElementById('unitRatio');
            var chartBox = echarts.init(unitRatio);
            chartBox.setOption(option);
          });
      }
    },

    getQuery() {
      if (this.query.departmentId) {
        this.query.channel = this.$refs.departments.getNode(this.query.departmentId).data.departmentName;
      }
      this.query.startDate = '';
      this.query.endDate = '';
      if (this.dateRange && this.dateRange.length > 1) {
        this.query.startDate = this.$util.fmtDate(this.dateRange[0], "yyyy-MM-dd");
        this.query.endDate = this.$util.fmtDate(this.dateRange[1], "yyyy-MM-dd") + " 23:59:59";
      }
      return this.query;
    },

    companyChange() {
      this.query.departmentId = '';

      var that = this;
      if (!this.query.companyId) {
        this.query.departmentId = '';
        that.fetchs();
        return;
      }

      this.$kaung.departments(this.query.companyId).then((res) => {
        that.departments = res;
        that.fetchs();
      });
    },

  },
  mounted() {
    this.query.companyId = this.$util.getLocalstorage('companyIdStr') || '';

    this.$kaung.dictionary("stock", "store").then(res => this.stores = res);
    this.$kaung.dictionary("stock", "channel").then(res => this.channels = res);

    this.dateRange = this.$util.getCurrentDate();

    this.$kaung.companys().then((res) => {
      this.companys = res;
      this.companyChange();
    });
  },
  created() { },
};
</script>